import React from "react";
import StoryPage from "../../components/story-page";
import { Helmet } from "react-helmet";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/edith-baxter.jpg";
  const fullName = "Edith Baxter";
  const zoomUrl = "https://us02web.zoom.us/j/86134293115";
  const zoomPhoneNumber = "(647) 558 0588";
  const zoomMeetingId = "861 3429 3115";
  const birthDate = "December 30, 1927";
  const deathDate = "November 3, 2020";
  const memorialDate = "Monday November 9th, 2020";
  const memorialTime = "1pm ET";
  const inviteText =
    "The family welcomes all who would like to join us virtually to celebrate the life of Edith. The above Zoom link will open up 30 minutes ahead of the 1 p.m. service. We thank you for joining us and look forward to the time we can actually gather in person to hug and share stories about the amazing life of Edith Baxter.";
  const obituary = `Family matriarch and die-hard Toronto Maple Leafs fan, Edith Baxter passed away on November 3, 2020, at home, surrounded by her family. Born Edith Newman in Usti nad Labem, in the former Czechoslovakia, on December 30, 1927, Edith spent her youth in England before coming to Canada at the end of WWII with her husband, William, her parents, and sister.
  \nEmotionally she never recovered from the loss of her son, Timothy (1994), but her commitment to family and tradition, her fierce loyalty and love, as well as her quiet strength, are all part of the legacy she leaves to those left to mourn. This includes her beloved daughter, Wendy McClung and son-in-law, David; daughter-in-law, Kathy Baxter; her grandchildren, Michael Baxter (Jill), Amy Wilkinson (Craig), Emily McClung (Jim), Caitlin McClung (Dave) and Mitchell McClung. But her greatest legacy might just be her eight great-grandchildren: Timothy, William, Christopher, Sabine, Jackson, Maeve, Beatrice and Henry.
  \nTogether with William, Edith founded Baxter Publications, which over the last 70 years evolved into a most trusted media company in Canada. Well-respected in the industry, her work afforded her the opportunity to travel the world. This along with her voracious appetite for current events and politics kept her mind sharp and her wit on point right up until her final days.
  \nMany will feel her loss including her cherished extended family and close friends, the staff of Baxter Media and her travel industry colleagues. The family wishes to thank MaryAnn, Marilou and Eva - along with all her wonderful caregivers - for the incredible care and compassion they provided Edith over the last couple of years.
  \nIn addition to her son, Edith was predeceased by her husband, William (2004); beloved parents, Gertrude Baumann (1973) and William Newman (1974); and her sister, Melita (1996).`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          async
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SgHSbb"
        ></script>
      </Helmet>
      <StoryPage
        location={location}
        imgUrl={imgUrl}
        fullName={fullName}
        zoomUrl={zoomUrl}
        zoomPhoneNumber={zoomPhoneNumber}
        zoomMeetingId={zoomMeetingId}
        optInviteChild=""
        customColumnSpacing="1fr 2fr"
        birthDate={birthDate}
        deathDate={deathDate}
        memorialDate={memorialDate}
        memorialTime={memorialTime}
        inviteText={inviteText}
        obituary={obituary}
        storiesRecordUrl={storiesRecordUrl}
        storiesSubmissionsUrl={storiesSubmissionsUrl}
      >
        <p>
          In lieu of flowers, the family is requesting donations in Edith's name
          be made to St. Michael's Hospital Foundation. Gifts can be made by
          calling (416) 864.5000 or online at{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="//www.stmichaelsfoundation.com/donate"
          >
            www.stmichaelsfoundation.com/donate
          </a>
          .
        </p>
        <iframe
          title="youtube-slideshow"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/9hlklIBH3qE"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </StoryPage>
    </>
  );
};

export default ThisPage;
